<mat-card fxLayout="column" fxLayoutAlign="center center" >
    <div fxLayout="column" fxLayoutGap="14px">
        <div>
            <div>Game Mode</div>
            <mat-form-field appearance="outline"  class="fullwidth">
                <mat-select  [(value)]="selectedGame" >
                  <mat-option *ngFor="let game of Games" [value]="game">
                    {{game.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div *ngIf="selectedGame.types !== undefined">
            <div>Type</div>
            <div class="game_type">
                <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    [(ngModel)]="gameType" *ngFor="let type of gameTypes; let i = index;">
                    <mat-radio-button [ngClass]="{'active': gameType === type}" disableRipple="true" [value]="type">{{type}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="selectedGame.legs !== undefined">
            <div>legs (First to)</div>
            <mat-form-field appearance="outline" class="fullwidth">
                <input type="number" matInput [(ngModel)]="selectedGame.legs">
            </mat-form-field>
        </div>
        <div *ngIf="selectedGame.start_score !== undefined">
            <div>Starting from</div>
            <mat-form-field appearance="outline" class="fullwidth">
                <input type="number" matInput [(ngModel)]="selectedGame.start_score">
            </mat-form-field>
        </div>
        <div *ngIf="selectedGame.easy_mode !== undefined ">
            <mat-slide-toggle [(ngModel)]="selectedGame.easy_mode" >Easy mode</mat-slide-toggle>
        </div>
        <div *ngIf="selectedGame.max_players > 0">
            <div>players ({{selectedGame.players.length}})</div>
            <div *ngFor="let player of selectedGame.players; let i =index">
                <mat-form-field appearance="outline"  [ngClass]="{'fullwidth': i >= 0}"   >
                    <mat-label>player {{i + 1}}</mat-label>
                    <input matInput [(ngModel)]="selectedGame.players[i].name" >
                </mat-form-field>
            </div>
            <div  fxLayout="row" fxLayoutAlign="space-evenly stretch" > 
                <div fxFlex *ngIf="selectedGame.players.length < selectedGame.max_players"  class="add_player"   (click)="selectedGame.players.push({name:''})">
                    <mat-icon>add</mat-icon> <span class="icon-label" >Add player</span>
                </div>
                <div *ngIf="selectedGame.players.length > 1" fxFlex class="add_player" (click)="selectedGame.players.splice(-1,1);">
                    <mat-icon>remove</mat-icon> <span class="icon-label" >Remove player</span>
                </div>
            </div>
        </div>
        <div class="error"  *ngIf="error">
            <div *ngFor="let error of errorMessages">{{error}}</div>
        </div>
        <button mat-raised-button color="primary" class="fullwidth" (click)="startGame()">Game On</button>

    </div>

</mat-card>